import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "../../components/PeopleCaroussel/PeopleCaroussel.css"
import dot1 from "../../imgs/dot-thing1.svg"
import dot2 from "../../imgs/dot-thing2.svg"

const peopleCaroussel = ({ people }) => {
    console.log(people);
    return (
        <div className='people-container'>
            <Slide>
                {people.map((person, index) => (
                    <div key={index} className="each-slide-effect">
                        <div>
                            <div className='left-name'>
                                <img src={dot1}  alt='' className='left-name-img-right'/>
                                <span>{person.nome}</span>
                                <img src={dot2} alt=''className='left-name-img-left' />
                            </div>
                            <div className='right-text'>{person.texto}</div>
                        </div>
                    </div>
                ))}

            </Slide>
        </div>
    );
};

export default peopleCaroussel;