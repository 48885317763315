import "../PastYearsCaroussel/PastYearsCaroussel.css"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useState,useEffect } from "react";

const PastYearsCaroussel = ({ images }) => {
    const [slidesToShow, setSlidesToShow] = useState(window.innerWidth <= 850 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setSlidesToShow(window.innerWidth <= 850 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='past-container'>
            <Slide slidesToShow={slidesToShow}>
                {images && images.map((img, index) => (
                    <div className="each-slide-effect-images" key={index}>
                        <div>
                            <img src={img.image} className="imagem-car" alt='' />
                        </div>
                    </div>
                ))}

            </Slide>
        </div>
    )
}

export default PastYearsCaroussel