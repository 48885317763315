import "../FaqsDropdown/FaqsDropdown.css"
import { useState } from "react";
import setaDown from "../../../imgs/seta-down.svg"
import setaUp from "../../../imgs/seta-up.svg"


const FaqsDropdown = ({questions}) => {
    
    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleDropdown = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className="faqs-dropdown-container">
            {questions.map((question, index) => (
                <div key={index} className={`color${index}`}>
                    <div className="faqs-box" key={index} onClick={() => toggleDropdown(index)}>
                        <div className="faq-inside">
                            <div className="faq-question" >
                                {question[0]}
                            </div>
                            {openQuestion === index && (
                                <div className="faq-answer">
                                    {question[1]}
                                </div>
                            )}

                        </div>
                        <div>
                            {openQuestion === index ? (
                                <img src={setaUp} />
                            ) : <img src={setaDown} />}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FaqsDropdown