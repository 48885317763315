import "../SpecificInternship/SpecificInternship.css"
import imagemTestes from "../../imgs/imagem-de-testes.svg"
import seta from "../../imgs/seta-back.svg"

const SpecificInternship = ({props,setInternshipSelected}) => {
    
    const c = "https://dotworks24.pythonanywhere.com"+ props.company.imagem
    
    return (
        <div className="internship-box">
            <img src={c} alt='' className="imagem-specific  "/>
            <div className="internship-infos">
                <div className="internship-title">
                    {props.type.type_name + " - " + props.internship_name}
                </div>
                <div className="internship-ok">
                    <div className="internship-little-info">
                        Coimbra
                    </div>
                    <div className="internship-little-info">
                        {props.date.start_date + " até " + props.date.end_date}
                    </div>
                </div>
            </div>
            <div className="internship-descrip">
                {props.description}
            </div>
            <div className="internship-back-button" onClick={()=>(setInternshipSelected(true))}>
                <img src={seta} alt='' />
                <div className="back-button-text" >
                    Voltar
                </div>
            </div>
        </div>
    )
}

export default SpecificInternship