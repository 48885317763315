import "../Homepage/Homepage.css"

import Navbar from "../../components/Navbar/Navbar"
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile"
import Faqs from "../../components/Faqs/Faqs"
import Caroussel from "../../components/Caroussel/Caroussel"
import Footer from "../../components/Footer/Footer"

import middleWorks from "../../imgs/homepage-img1.svg"
import leftWorks from "../../imgs/left-works.svg"
import rightWorks from "../../imgs/right-works.svg"
import PeopleCaroussel from "../../components/PeopleCaroussel/PeopleCaroussel"

import video from "../../video/logomovimento.mp4"
import { useState, useEffect } from "react"

const Homepage = ({ props ,questions}) => {

    const handleRegistration = () => {
        window.location.href = 'https://docs.google.com/forms/d/1-ipZsyUooeQCMa6A2j5cRaLxzSsZx4VH2xbL6WcvL78/edit';
    }
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        // Limpa o listener do evento de redimensionamento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <div className="homepage">
            {isMobile === 1 ?
                <div>
                    <NavbarMobile />
                    <div className="mobile-cont">
                        <video className="video-dot-mobile" autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                        </video>
                        <div className="okapa">
                            <div className='registrations-mobile' onClick={handleRegistration}>
                                <div>
                                    INSCREVE-TE!
                                </div>
                            </div>
                        </div>
                    </div></div>
                :
                <div>
                    <Navbar />
                    <div className='under-nav'>
                        <video className="video-dot" autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                        </video>
                        <div className='texto-dali'>
                            8 DE MAIO
                        </div>
                        <div className='inscricao-boxx' onClick={handleRegistration}>
                            <div className='inscricao-boxx-text' >
                                INSCREVE-TE
                            </div>
                        </div>
                    </div>
                </div>}

            <div className="middle-element">
                <img src={middleWorks} alt="" />
                <div className="middle-element-text">
                    <div className="tittle-text">
                        VEM MARCAR PONTOS CONNOSCO
                    </div>
                    <div className="middle-element-text-2">
                        Gostavas de ter um estágio este verão mas já deixaste passar todos os períodos de candidaturas? Ou simplesmente nenhum de interessou?
                        Ou estás a preparar-te para o teu próximo ano letivo e gostarias de fazer um estágio curricular? Estás a acabar o curso e não sabes onde fazer o teu primeiro estágio profissional?
                        <br /><br />
                        .works é um evento organizado pela jeKnowledge que
                        junta diversas empresas das áreas tecnológicas! Terás ainda
                        a oportunidade de ter entrevistas informais com as tuas preferidas!
                    </div>
                </div>
            </div>
            <Caroussel />
            <div className="under-caroussel">
                <img src={leftWorks} alt='' className="under-caroussel-left-img" />

                <div className="under-caroussel-text tittle-text">
                    ENCONTRA TODOS OS ESTÁGIOS
                    QUE TEMOS PARA TE OFERECER
                </div>
                <img src={rightWorks} alt='' className="under-caroussel-right-img" />

            </div>
            <PeopleCaroussel people={props} />
            <Faqs questions={questions}/>
            <Footer />
        </div>
    )
}

export default Homepage
