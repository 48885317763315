import { useState } from "react";
import "../FiltersDropdown/FiltersDropdown.css"
import setaBaixo from "../../../imgs/seta-down-white.svg"
import setaCima from "../../../imgs/seta-up-white.svg"
import x from "../../../imgs/x.svg"

const FiltersDropdown = (props) => {
    const { title, options } = props;
    const [selectedFilter, setSelectedFilter] = useState(localStorage.getItem(title) ||  null);
    console.log(selectedFilter)
    const [openDrop, setOpenDrop] = useState(true);
    
    const toggleDropdown = () => {
        setOpenDrop(!openDrop);
    };

    const updateURL = (filter) => {
        const searchParams = new URLSearchParams(window.location.search);

        const hasActiveFilters = options.some(opt => opt !== selectedFilter);

        if (filter && hasActiveFilters) {
            searchParams.set(title.toLowerCase(), filter);
        } else {
            searchParams.delete(title.toLowerCase());
        }

        if (searchParams.toString()) {
            window.history.pushState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
        } else {
            window.history.pushState({}, "", `${window.location.pathname}`);
        }
        window.location.reload();
    };
    
    const handleFilterSelection = (filter) => {
        setSelectedFilter(filter);
        localStorage.setItem(title, filter);
        updateURL(filter);
    };



    return (
        <div className="dropdown-box">
            <div className="dropdown-title" onClick={toggleDropdown}>
                <div>
                    {title}</div>
                <img src={openDrop === true ? setaCima : setaBaixo} alt='' />
            </div>
            {openDrop === true ?
                <div className="filters-inside-box">
                    {options.map((filter, index) => (
                        <div className="tipoooo" key={index} onClick={() => handleFilterSelection(filter)}>
                            {filter === selectedFilter ? <div className="circle2"></div> : <div className="circle"></div>}
                            {filter}
                        </div>
                    ))}
                    <div className="clear-button" onClick={()=> handleFilterSelection(null)}>
                        <img src={x} alt=""/>
                        <div>Limpar</div>
                    </div>
                </div>

                :
                null
            }
        </div>
    )
}

export default FiltersDropdown