import "../Testemunhos/Testemunhos.css"
import leftTest0 from "../../imgs/testemunho-thing1.svg"
import rightTest0 from "../../imgs/testemunho-thing2.svg"
import leftTest1 from "../../imgs/testemunho-thing3.svg"
import rightTest1 from "../../imgs/testemunho-thing4.svg"
import leftTest2 from "../../imgs/testemunho-thing5.svg"
import rightTest2 from "../../imgs/testemunho-thing6.svg"
import leftTest3 from "../../imgs/testemunho-thing7.svg"
import rightTest3 from "../../imgs/testemunho-thing8.svg"
import leftTest4 from "../../imgs/testemunho-thing9.svg"
import rightTest4 from "../../imgs/testemunho-thing10.svg"

const Testemunhos = ({ testemunhos }) => {
    const leftImages = [leftTest0, leftTest1, leftTest2, leftTest3, leftTest4];
    const rightImages = [rightTest0, rightTest1, rightTest2, rightTest3, rightTest4];

    return (

        <div className="testemunhos-container">
            {testemunhos.map((testemunho, index) => (
                <div className="testemunho" key={index}>
                    <div className="testemunho-year">
                        <img src={leftImages[index]} className={`left-test-img left-test-img-${index}`} alt='' />
                        <img src={rightImages[index]} className={`right-test-img right-test-img-${index}`} alt='' />
                        <div>
                            {testemunho.ano}
                        </div>
                    </div>
                    <div className="testemunho-text">
                        {testemunho.texto}
                    </div>
                </div>
            ))
            }

        </div>
    )
}

export default Testemunhos