import "../Internships/Internships.css"
import Navbar from "../../components/Navbar/Navbar"
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";
import internships from "../../imgs/image-internships.svg"
import Filters from "../../components/Filters/Filters";
import axios from "axios";
import CardInternship from "../../components/CardInternship/CardInternship";
import SpecificInternship from "../../components/SpecificInternship/SpecificInternship";


const Internships = ({ filters }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900 ? 1 : 3);
    const [allInternships, setAllInternships] = useState([]);
    const [uniqueCompanies, setUniqueCompanies] = useState([]);
    const [internshipSelected, setInternshipSelected] = useState(true);
    const [internshipInfo, setInternshipInfo] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900 ? 1 : 3);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(window.location.search).toString();
            try {

                const response = await axios.get(`https://dotworks24.pythonanywhere.com/api/internships/?${queryParams}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const internshipsData = response.data;
                const companies = internshipsData.map(internship => internship.company.company_name);
                const uniqueCompanies = Array.from(new Set(companies));
                setAllInternships(internshipsData);
                setUniqueCompanies(uniqueCompanies.sort());
            } catch (error) {
                console.log('Erro ao fazer solicitação:', error);
            }
        };
        fetchData();
    }, [filters.areas]);

    return (
        <div className="internships-webpage">
            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
                    
                <img src={internships} alt='' className="imagem-intern" />
            

            <div className="internships-filters">
                <div>
                    <Filters filters={filters} />
                </div>
                <div className="middle-interns">
                    {internshipSelected && <div className="empresas">EMPRESAS</div>}
                    {internshipSelected && uniqueCompanies.map(companyName => (
                        <div className="internships-outbox" key={companyName}>
                            <div className="company-name">{companyName}</div>
                            <div className="internships-cards-box">
                                {allInternships && allInternships.filter(internship => internship.company.company_name === companyName).map(internship => (
                                    <div className="quadrado" key={internship.id} onClick={() => (
                                        setInternshipInfo(internship),
                                        setInternshipSelected(!internshipSelected)
                                    )}>
                                        <CardInternship nome={internship.internship_name} imagem={internship.company.imagem} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    {!internshipSelected && (
                        <div>
                            <SpecificInternship props={internshipInfo} setInternshipSelected={setInternshipSelected} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Internships