import "../CardInternship/CardInternship.css"

//nas props vai entrar o nome do estágio e a imagem
const CardInternship = ({nome,imagem}) => {
    
    const c = "https://dotworks24.pythonanywhere.com"+imagem
    
    return(
        <>
            <div className="internship-text">
                {nome}
            </div>
            <div>
                <img src={c} alt='' className="imagem-card"/>
            </div>
        </>
    )
}

export default CardInternship