import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";

import "../Partners/Partners.css"
import leftWorks from "../../imgs/left-works2.svg"
import rightWorks from "../../imgs/right-works2.svg"

const Partners = ({ images }) => {

    console.log(images)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        // Limpa o listener do evento de redimensionamento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="homepage">
            {isMobile == 1 ? <NavbarMobile /> : <Navbar />}
            <div className="partners-middle">
                <img src={leftWorks} alt='' className="left-works-partners" />
                <img src={rightWorks} alt='' className="right-works-partners" />
                <div className="faqs-text more">
                    OS NOSSOS PARCEIROS
                </div>
                <div>
                    {images.map((img, index) => (
                        <img key={index} src={img} alt='' />
                    ))

                    }
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Partners;