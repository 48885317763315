import "../PreviousEditions/PreviousEditions.css"
import Navbar from "../../components/Navbar/Navbar"
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile"
import { useEffect,useState } from "react"
import PastYearsCaroussel from "../../components/PastYearsCaroussel/PastYearsCaroussel"
import Testemunhos from "../../components/Testemunhos/Testemunhos"

const PreviousEditions = ({images,testemunhos}) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900 ? 1 : 3);
    console.log(images)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="previous-page">
            {isMobile == 1 ? <NavbarMobile /> : <Navbar />}
            <PastYearsCaroussel images={images}/>
            <Testemunhos testemunhos={testemunhos}/>
        </div>
    )
}

export default PreviousEditions