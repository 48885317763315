import "../Caroussel/Caroussel.css"
import accenture from "../../caroussel-logos/accenture.png"
import altice from "../../caroussel-logos/altice.png"
import redlight from "../../caroussel-logos/redlight.png"
import subvisual from "../../caroussel-logos/subvisual.png"
import tuu from "../../caroussel-logos/tuu.png"
import ninjas from "../../caroussel-logos/ninjas.jpg"

const Caroussel = () => {
    return (
        <div className="carousel-container">
            <div className="slider">
                <div className="slide-track">
                    <div className="slide">
                        <img src={accenture} alt='' height="90" width="250"/>
                    </div>
                    <div className="slide">
                        <img src={altice} alt='' height="110" width="250"/>
                    </div>
                    <div className="slide">
                        <img src={redlight} alt=''height="90" width="250" />
                    </div>
                    <div className="slide">
                        <img src={subvisual} alt=''height="85" width="250" />
                    </div>
                    <div className="slide">
                        <img src={tuu} alt=''height="90" width="150" />
                    </div>
                    <div className="slide">
                        <img src={ninjas} alt=''height="120" width="230" />
                    </div>
                    <div className="slide">
                        <img src={accenture} alt='' height="90" width="250"/>
                    </div>
                    <div className="slide">
                        <img src={altice} alt='' height="110" width="250"/>
                    </div>
                    <div className="slide">
                        <img src={redlight} alt=''height="90" width="250" />
                    </div>
                    <div className="slide">
                        <img src={subvisual} alt=''height="85" width="250" />
                    </div>
                    <div className="slide">
                        <img src={tuu} alt=''height="90" width="150" />
                    </div>
                    <div className="slide">
                        <img src={ninjas} alt=''height="120" width="230" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Caroussel