import '../Navbar/Navbar.css'
import React, { useState, useEffect } from 'react';
import works from "../../imgs/works-gif.svg"
import video from "../../video/logomovimento.mp4"
import { useNavbarContext } from '../../Context/context';
import { Link } from 'react-router-dom';

const Navbar = () => {

    const { activeButton, setActiveButton } = useNavbarContext();

    const handleRegistration = () => {
        window.location.href = 'https://docs.google.com/forms/d/1-ipZsyUooeQCMa6A2j5cRaLxzSsZx4VH2xbL6WcvL78/edit';
    }

    const handleMainPage = () => {
        window.location.href = 'https://dotworks.jeknowledge.com//';
    }

    const handleClick = (index) => {
        setActiveButton(index);
        if (index === 3) {
            if (window.location.href !== 'https://dotworks.jeknowledge.com//') {
                window.location.href = 'https://dotworks.jeknowledge.com//'
            }
            else {
                const aboutSection = document.getElementById('faqs');
                aboutSection?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    return (
        <div>
            <div className="navbar-container">
                <div className='w-text' onClick={handleMainPage}>
                    .W
                </div>
                <div className='buttons-container'>
                    <Link
                        to="/previousEditions"
                        className={`buttons-container-a ${activeButton === 0 ? "selected-button" : "pressed-button"}`}
                        onClick={() => handleClick(0)}>
                        EDIÇÕES PASSADAS
                    </Link>
                    <Link
                        to="/internships"
                        className={`buttons-container-a ${activeButton === 1 ? "selected-button" : "pressed-button"}`}
                        onClick={() => handleClick(1)}>
                        ESTÁGIOS
                    </Link>
                    <Link
                        to="/partners"
                        className={`buttons-container-a ${activeButton === 2 ? "selected-button" : "pressed-button"}`}
                        onClick={() => handleClick(2)}>
                        PARCEIROS
                    </Link>
                    <div
                        className={`buttons-container-a ${activeButton === 3 ? "selected-button" : "pressed-button"}`}
                        onClick={() => handleClick(3)}>
                        FAQ
                    </div>
                </div>
                <div className='registrations' onClick={handleRegistration}>
                    <div>
                        INSCRIÇÕES
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;