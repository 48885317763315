import "../NavbarMobile/NavbarMobile.css"
import ok from "../../../imgs/works-gif.svg"
import points from "../../../imgs/points.svg"
import blackpoints from "../../../imgs/black-points.svg"
import works from "../../../imgs/mobile-works.svg"
import video from "../../../video/logomovimento.mp4"

import { useState } from "react"

const NavbarMobile = () => {
    const [menu, setMenu] = useState(false);

    const handleRegistration = () => {
        window.location.href = 'https://docs.google.com/forms/d/1-ipZsyUooeQCMa6A2j5cRaLxzSsZx4VH2xbL6WcvL78/edit';
    }
    
    const handleMainPage = () => {
        window.location.href = 'https://dotworks.jeknowledge.com//';
    }

    const handleMenu = () => {
        setMenu(!menu);
    }

    const handleClick = (index) => {
        if (index === 0) {
            window.location.href='https://dotworks.jeknowledge.com//previousEditions'
        } else if (index === 1) {
            window.location.href='https://dotworks.jeknowledge.com//internships'
        } else if (index === 2) {
            window.location.href='https://dotworks.jeknowledge.com//partners'
        } else {
            if (window.location.href !== 'https://dotworks.jeknowledge.com//') {
                window.location.href = 'https://dotworks.jeknowledge.com//'
            } else {
                const aboutSection = document.getElementById('faqs');
                aboutSection.scrollIntoView({ behavior: 'smooth' });
            }

        }
    }

    return (
        <>
            {menu == false ? (
            <div className="mobile-display">
                <div className="navbar-container-mobile">
                    <div className="w-text-mobile" onClick={handleMainPage}>
                        .w
                    </div>
                    <img src={points} onClick={handleMenu} alt='' />
                </div>
                
            </div>) :
                (
                    <div className="mobile-display-theme">
                        <div className="navbar-container-mobile-theme">
                            <img src={blackpoints} onClick={handleMenu} alt=''/>
                        </div>
                        <div className="options-container-mobile options-container-text-mobile">
                            {["EDIÇÕES PASSADAS", "ESTÁGIOS", "PARCEIROS", "FAQ"].map((buttonName, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleClick(index)}>
                                    {buttonName}
                                </div>
                            ))}
                        </div>
                        <div className="img-container">
                            <img src={works} className="imagem-mobile" alt='' />
                        </div>
                    </div>
                )}
        </>
    )
}

export default NavbarMobile