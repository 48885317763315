import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext();

export const useNavbarContext = () => useContext(NavbarContext);

export const NavbarProvider = ({ children }) => {
    const [activeButton, setActiveButton] = useState(5);

    return (
        <NavbarContext.Provider value={{ activeButton, setActiveButton }}>
            {children}
        </NavbarContext.Provider>
    )
}