import "../Filters/Filters.css"
import FiltersDropdown from "../Filters/FiltersDropdown/FiltersDropdown"

const Filters = ({filters}) => {
    return (
        <div className="filters-box">
            <FiltersDropdown title={filters[0].title} options={filters[0].filters}/>
            <FiltersDropdown title={filters[1].title} options={filters[1].filters}/>
            <FiltersDropdown title={filters[2].title} options={filters[2].filters}/>
            <FiltersDropdown title={filters[3].title} options={filters[3].filters}/>
        </div>
    )
}

export default Filters