import "../Faqs/Faqs.css"
import faqs from "../../imgs/faqs-img.svg"
import FaqsDropdown from "./FaqsDropdown/FaqsDropdown"


const Faqs = ({questions}) =>{
    return(
        <section id="faqs" className="faqs-container">
            <div className="title-container">
                <div className="faqs-text">
                    E COMO FORAM AS 
                    EDIÇÕES ANTERIORES?
                </div>
            </div>
            <div className="down-container">
                <div className="faqs-dropdowns">
                    <div className="faqs-text2">
                        FAQ
                    </div>
                    <div>
                        <FaqsDropdown questions={questions}/>
                    </div>
                    
                </div>
                <div className="ok">
                    <img src={faqs} />
                </div>
            </div>

        </section>
    )
}

export default Faqs